<template>
  <v-dialog v-model='dlgPre' scrollable persistent max-width='700px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          Create Prescription
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap>
          <v-flex sm6>
            <div style='margin-right: 10px'>
              <v-menu
                ref='dtMenu'
                :close-on-content-click='false'
                lazy
                nudge-right='40'
                offset-y
                full-width
                max-width='290px'
                min-width='290px'
                v-model='dtMenu'
              >
                <template v-slot:activator='{ on }'>
                  <v-text-field
                    v-model='dlgModel.PDate'
                    label='Prescription date:'
                    v-on='on'
                    readonly
                  >
                    <template slot='append'>
                      <v-icon class='filterIcon grey--text text--darken-1'
                        >fa fa-calendar</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model='dlgModel.PDate'
                  no-title
                  @input='dtMenu = false'
                ></v-date-picker>
              </v-menu>
            </div>
          </v-flex>
          <v-flex sm6>
            <div style='margin-left: 10px'>
              <v-combobox
                :items='months'
                v-model='dlgModel.ReviewAfter'
                label='Review after:'
              >
              </v-combobox>
            </div>
          </v-flex>
        </v-layout>
        <!--<v-autocomplete-->
        <!--label='Medicine:'-->
        <!--:items='drugs'-->
        <!--v-model='drug'-->
        <!--item-text='Name'-->
        <!--return-object-->
        <!--&gt;</v-autocomplete>-->
        <v-text-field
          v-model.trim='drug'
          label='Drug:'
          counter='100'
          maxlength='100'
        ></v-text-field>
        <v-text-field
          v-model.trim='composition'
          label='Composition:'
          counter='100'
          maxlength='100'
        ></v-text-field>
        <v-text-field
          v-model.trim='dose'
          label='Dose:'
          counter='100'
          maxlength='100'
        ></v-text-field>
        <v-text-field
          v-model.trim='dosage'
          label='Dosage:'
          counter='100'
          maxlength='100'
        ></v-text-field>
        <v-btn color='primary' @click='addItem'>Add medicine</v-btn>
        <br />
        <br />
        <v-data-table
          :headers='headers'
          :items='items'
          hide-actions
          class='elevation-2'
          style='height: 200px;overflow: auto'
        >
          <template slot='items' slot-scope='props'>
            <tr>
              <td>{{ props.item.Name }}</td>
              <td>{{ props.item.Composition }}</td>
              <td>{{ props.item.Dose }}</td>
              <td>{{ props.item.Dosage }}</td>
              <td style='text-align: center'>
                <button
                  class='linkBtn'
                  type='button'
                  @click='removeItem(props.item)'
                >
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </v-data-table>
        <br />
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>
          Close
        </v-btn>
        <v-btn
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';

export default {
  props: {
    pid: { type: Number, default: 0 },
    dlgPre: { type: Boolean, default: false },
    drugsPre: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  // computed: {
  //   drugs() {
  //     return this.$store.getters['DrugsStore/Drugs'];
  //   }
  // },
  watch: {
    drugsPre: function() {
      this.items = this.drugsPre;
    }
    // drug: function() {
    //   if (this.drug.Id) {
    //     this.dose = this.drug.Dose;
    //     this.dosage = this.drug.Dosage;
    //   }
    // }
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'Name',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Compositions',
          value: 'Composition',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Dose',
          value: 'Dose',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Dosage',
          value: 'Dosage',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          width: '5%',
          align: 'center'
        }
      ],
      months: ['2 months', '4 months', '6 months', '8 months'],
      items: [],
      dose: '',
      dosage: '',
      drug: '',
      composition: '',
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      dlgModel: {
        ReviewAfter: '',
        PDate: '',
        Drugs: ''
      }
    };
  },
  methods: {
    closeDlg() {
      this.items = [];
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        ReviewAfter: '',
        PDate: '',
        Drugs: ''
      };
      this.$emit('close');
    },
    addItem() {
      if (this.drug === '') {
        return;
      }
      if (this.composition === '') {
        return;
      }
      if (this.dose === '') {
        return;
      }
      if (this.dosage === '') {
        return;
      }
      const d = {
        Name: this.drug,
        Composition: this.composition,
        Dose: this.dose,
        Dosage: this.dosage
      };
      this.items.push(d);
      // if (this.drug.Id) {
      //   const d = {
      //     Name: this.drug.Name,
      //     Composition: this.drug.Composition,
      //     Dose: this.dose,
      //     Dosage: this.dosage
      //   };
      //   this.items.push(d);
      // }

      //this.drug = {};
      this.drug = '';
      this.composition = '';
      this.dose = '';
      this.dosage = '';
    },
    removeItem(item) {
      const index = this.items.indexOf(item);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    },
    async saveDlg() {
      if (this.dlgModel.ReviewAfter === '') {
        await this.$notifyDlg.show(
          'Please enter review after',
          'Error',
          'error'
        );
        return;
      }
      if (this.dlgModel.PDate === '') {
        await this.$notifyDlg.show(
          'Please enter prescription date',
          'Error',
          'error'
        );
        return;
      }
      if (this.pid < 1) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      if (this.items.length < 1) {
        await this.$notifyDlg.show('Add medicines ', 'Error', 'error');
        return;
      }
      this.dlgModel.Drugs = JSON.stringify(this.items);
      this.dlgModel.PId = this.pid;
      this.api_create(this.dlgModel);
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/prescriptions/old', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
