<template>
  <v-card class='elevation-0'>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color='primary' outline @click='showDlg'>Edit</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex sm4> Name:&nbsp;&nbsp; {{ patient.Name }} </v-flex>
        <v-flex sm3> Patient Id:&nbsp;&nbsp; {{ patient.PatientId }} </v-flex>
        <v-flex sm2> Age:&nbsp;&nbsp; {{ patient.DOB | DobToAge }} </v-flex>
        <v-flex sm2>
          Gender:&nbsp;&nbsp; {{ patient.Gender | genderIntString }}
        </v-flex>
        <v-flex sm4> Mobile:&nbsp;&nbsp; {{ patient.Mobile }} </v-flex>
        <v-flex sm4> Email:&nbsp;&nbsp; {{ patient.Email }} </v-flex>
        <v-flex sm12> Address:&nbsp;&nbsp; {{ patient.Address }} </v-flex>
        <v-flex sm6>City:&nbsp;&nbsp;{{ patient.City }}</v-flex>
        <v-flex sm6>PIN:&nbsp;&nbsp;{{ patient.PIN }}</v-flex>
      </v-layout>
      <div>
        <PatientDlg
          :dlg-show='dlgShow'
          :patient='patient'
          :dlg-type='1'
          @close='closeDlg'
          @add='addDlg'
        ></PatientDlg>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PatientDlg from '../../components/patients/OldPatientDlg';
export default {
  components: {
    PatientDlg
  },
  props: {
    patient: {
      type: Object,
      //default: null
      default: () => {
        return {
          Name: '',
          PatientId: '',
          Age: '',
          Gender: 0,
          Mobile: '',
          Address: '',
          City: '',
          PIN: '',
          Email: ''
        };
      }
    }
  },
  data() {
    return {
      dlgShow: false
    };
  },
  methods: {
    showDlg() {
      if (this.patient.Id) {
        this.dlgShow = true;
      }
    },
    closeDlg() {
      this.dlgShow = false;
    },
    async addDlg() {
      await this.$notifyDlg.show(
        'To get the updated details please reload patient data by searching again',
        'Info',
        'info'
      );
    }
  }
};
</script>

<style scoped></style>
