<template>
  <v-dialog v-model='dlgInvest' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : 'Edit' }} investigation details
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <div class='subheading'>PEFR:</div>
          <v-layout row wrap>
            <v-flex xs4 style='padding: 1%;'>
              <v-text-field
                :disabled='dlgType === 2'
                v-model.number='dlgModel.PEFRPred'
                label='Pred:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%;'>
              <v-text-field
                :disabled='dlgType === 2'
                v-model.number='dlgModel.PEFRPre'
                label='Pre:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%;'>
              <v-text-field
                :disabled='dlgType === 2'
                v-model.number='dlgModel.PEFRPost'
                label='Post:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
        <v-text-field
          :disabled='dlgType === 2'
          v-model.number='dlgModel.SpO2'
          label='SpO2 (%):'
        ></v-text-field>
        <div>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                v-model.trim='dlgModel.CRP'
                label='CRP:'
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-radio-group v-model='dlgModel.CRPSt' row>
                <v-radio label='Positive' value='positive' color='primary' />
                <v-radio label='Negative' value='negative' color='primary' />
              </v-radio-group>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.Urea'
                label='Urea breath test:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.Skin'
                label='Skin-KOH:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.Sweat'
                label='Sweat chloride test:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.Nasal'
                label='Nasal flow rate:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>

        <div>
          <div class='subheading'>TFT:</div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.TFT_T3'
                label='T3:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.TFT_T4'
                label='T4:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.TFT_TSH'
                label='TSH:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.TFT_Sputum'
                label='Sputum:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <div class='subheading'>PFT:</div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.PFT_FVC'
                label='FVC:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.PFT_FEV1_Pre'
                label='FEV1 Pre:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.PFT_FEV1_Post'
                label='FEV1 Post:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%;'>
              <v-text-field
                v-model.trim='dlgModel.PFT_FEV1_FVC'
                label='FEV1/FVC:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>

        <v-text-field v-model.trim='dlgModel.DLco' label='DLco:'></v-text-field>

        <div>
          <div class='subheading'>Mucous clearance test:</div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Mucous_R'
                label='R:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Mucous_L'
                label='L:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <div class='subheading'>Nasal smear:</div>
          <div class='body-2'>Rt:</div>
          <v-layout row wrap>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Nasal_Rt_N'
                label='N:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Nasal_Rt_L'
                label='L:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Nasal_Rt_E'
                label='E:'
              ></v-text-field
            ></v-flex>
          </v-layout>
          <div class='body-2'>Lt:</div>
          <v-layout row wrap>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Nasal_Lt_N'
                label='N:'
              ></v-text-field
            ></v-flex>
            <v-flex xs4 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Nasal_Lt_L'
                label='L:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Nasal_Lt_E'
                label='E:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <div class='subheading'>Conjunctiva smear:</div>
          <div class='body-2'>Rt:</div>
          <v-layout row wrap>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Conj_Rt_N'
                label='N:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Conj_Rt_L'
                label='L:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Conj_Rt_E'
                label='E:'
              ></v-text-field
            ></v-flex>
          </v-layout>
          <div class='body-2'>Lt:</div>
          <v-layout row wrap>
            <v-flex xs4 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Conj_Lt_N'
                label='N:'
              ></v-text-field
            ></v-flex>
            <v-flex xs4 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Conj_Lt_L'
                label='L:'
              ></v-text-field>
            </v-flex>
            <v-flex xs4 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Conj_Lt_E'
                label='E:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>

        <div>
          <div class='subheading'>Thermo stimulatory test:</div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%'>
              <v-text-field
                v-model.trim='dlgModel.Th_Pressure'
                label='Pressure:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Th_Heat'
                label='Heat:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Th_Cold'
                label='Cold:'
              ></v-text-field>
            </v-flex>
            <v-flex xs6 style='padding: 1%'
              ><v-text-field
                v-model.trim='dlgModel.Th_Sweat'
                label='Sweat:'
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <v-layout row wrap>
            <v-flex xs6 style='padding: 1%;'
              ><v-text-field
                v-model.trim='dlgModel.Serum'
                label='Serum test:'
              ></v-text-field
            ></v-flex>
            <v-flex xs6 style='padding: 1%;'
              ><v-text-field
                v-model.trim='dlgModel.ATgAB'
                label='ATgAB:'
              ></v-text-field
            ></v-flex>
            <v-flex xs6 style='padding: 1%;'
              ><v-text-field
                v-model.trim='dlgModel.ATPO'
                label='ATPO:'
              ></v-text-field
            ></v-flex>
            <v-flex xs6 style='padding: 1%;'
              ><v-text-field
                v-model.trim='dlgModel.ANA'
                label='ANA:'
              ></v-text-field
            ></v-flex>
          </v-layout>
        </div>
        <v-menu
          ref='dtMenu'
          :close-on-content-click='false'
          lazy
          nudge-right='40'
          offset-y
          full-width
          max-width='290px'
          min-width='290px'
          v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
              v-model='dlgModel.AnsDate'
              label='Select a date:'
              v-on='on'
              name='rDt'
              readonly
              v-validate='{ required: true }'
              :error='!!errors.has("rDt")'
              :error-messages='errors.first("rDt")'
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='dlgModel.AnsDate'
            no-title
            @input='dtMenu = false'
          ></v-date-picker>
        </v-menu>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';

export default {
  props: {
    pid: { type: Number, default: 0 },
    dlgInvest: { type: Boolean, default: false },
    dlgType: {
      type: Number,
      default: 0
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    dlgInvest: function() {
      if (this.obj.Id) {
        this.dlgModel = {
          AnsDate: this.obj.AnsDate,
          CRP: this.obj.CRP,
          CRPSt: this.obj.CRPSt,
          Urea: this.obj.Urea,
          Skin: this.obj.Skin,
          Sweat: this.obj.Sweat,
          TFT_T3: this.obj.TFT_T3,
          TFT_T4: this.obj.TFT_T4,
          TFT_TSH: this.obj.TFT_TSH,
          TFT_Sputum: this.obj.TFT_Sputum,
          PFT_FVC: this.obj.PFT_FVC,
          PFT_FEV1_Pre: this.obj.PFT_FEV1_Pre,
          PFT_FEV1_Post: this.obj.PFT_FEV1_Post,
          PFT_FEV1_FVC: this.obj.PFT_FEV1_FVC,
          DLco: this.obj.DLco,
          Nasal: this.obj.Nasal,
          Mucous_R: this.obj.Mucous_R,
          Mucous_L: this.obj.Mucous_L,
          Nasal_Rt_N: this.obj.Nasal_Rt_N,
          Nasal_Rt_L: this.obj.Nasal_Rt_L,
          Nasal_Rt_E: this.obj.Nasal_Rt_E,
          Nasal_Lt_N: this.obj.Nasal_Lt_N,
          Nasal_Lt_L: this.obj.Nasal_Lt_L,
          Nasal_Lt_E: this.obj.Nasal_Lt_E,
          Conj_Rt_N: this.obj.Conj_Rt_N,
          Conj_Rt_L: this.obj.Conj_Rt_L,
          Conj_Rt_E: this.obj.Conj_Rt_E,
          Conj_Lt_N: this.obj.Conj_Lt_N,
          Conj_Lt_L: this.obj.Conj_Lt_L,
          Conj_Lt_E: this.obj.Conj_Lt_E,
          Serum: this.obj.Serum,
          Th_Pressure: this.obj.Th_Pressure,
          Th_Heat: this.obj.Th_Heat,
          Th_Cold: this.obj.Th_Cold,
          Th_Sweat: this.obj.Th_Sweat,
          ATgAB: this.obj.ATgAB,
          ATPO: this.obj.ATPO,
          ANA: this.obj.ANA
        };
      }
    }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      dlgModel: {
        AnsDate: '',
        CRP: '',
        CRPSt: '',
        Urea: '',
        Skin: '',
        Sweat: '',
        TFT_T3: '',
        TFT_T4: '',
        TFT_TSH: '',
        TFT_Sputum: '',
        PFT_FVC: '',
        PFT_FEV1_Pre: '',
        PFT_FEV1_Post: '',
        PFT_FEV1_FVC: '',
        DLco: '',
        Nasal: '',
        Mucous_R: '',
        Mucous_L: '',
        Nasal_Rt_N: '',
        Nasal_Rt_L: '',
        Nasal_Rt_E: '',
        Nasal_Lt_N: '',
        Nasal_Lt_L: '',
        Nasal_Lt_E: '',
        Conj_Rt_N: '',
        Conj_Rt_L: '',
        Conj_Rt_E: '',
        Conj_Lt_N: '',
        Conj_Lt_L: '',
        Conj_Lt_E: '',
        Serum: '',
        Th_Pressure: '',
        Th_Heat: '',
        Th_Cold: '',
        Th_Sweat: '',
        ATgAB: '',
        ATPO: '',
        ANA: ''
      }
    };
  },
  methods: {
    closeDlg() {
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        AnsDate: '',
        CRP: '',
        CRPSt: '',
        Urea: '',
        Skin: '',
        Sweat: '',
        TFT_T3: '',
        TFT_T4: '',
        TFT_TSH: '',
        TFT_Sputum: '',
        PFT_FVC: '',
        PFT_FEV1_Pre: '',
        PFT_FEV1_Post: '',
        PFT_FEV1_FVC: '',
        DLco: '',
        Nasal: '',
        Mucous_R: '',
        Mucous_L: '',
        Nasal_Rt_N: '',
        Nasal_Rt_L: '',
        Nasal_Rt_E: '',
        Nasal_Lt_N: '',
        Nasal_Lt_L: '',
        Nasal_Lt_E: '',
        Conj_Rt_N: '',
        Conj_Rt_L: '',
        Conj_Rt_E: '',
        Conj_Lt_N: '',
        Conj_Lt_L: '',
        Conj_Lt_E: '',
        Serum: '',
        Th_Pressure: '',
        Th_Heat: '',
        Th_Cold: '',
        Th_Sweat: '',
        ATgAB: '',
        ATPO: '',
        ANA: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      if (this.pid < 1 && this.dlgType === 0) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      if (this.dlgType === 0) {
        this.dlgModel.PId = this.pid;
        this.api_create(this.dlgModel);
      } else {
        this.dlgModel.Id = this.obj.Id;
        this.api_update(this.dlgModel);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/invest/old', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      const res = await WebH.Put('/invest/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
