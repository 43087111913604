<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Already existed patient data
        </div>
      </v-flex>
      <v-flex sm12 lg12>
        <!--<v-btn color='primary' @click='dlgShow = true' :loading='loading'>-->
        <!--<v-icon class='iconText'>fa fa-plus</v-icon>-->
        <!--Existing Patient-->
        <!--</v-btn>-->
        <!--<v-btn color='primary' :loading='loading' @click='dlgSurvey = true'>-->
        <!--<v-icon class='iconText'>fa fa-plus</v-icon>-->
        <!--Allergy Survey-->
        <!--</v-btn>-->
        <v-btn color='primary' :loading='loading' @click='dlgExam = true'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          Examination
        </v-btn>
        <v-btn color='primary' :loading='loading' @click='dlgInvest = true'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          Investigations
        </v-btn>
        <v-btn color='primary' :loading='loading' @click='dlgDig = true'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          Diagnosis
        </v-btn>
        <v-btn color='primary' :loading='loading' @click='dlgSpt = true'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          SPT
        </v-btn>
        <v-btn color='primary' :loading='loading' @click='dlgLab = true'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          Lab Report
        </v-btn>
        <!--<v-btn color='primary' :loading='loading' @click='createPrescription'>-->
        <!--<v-icon class='iconText'>fa fa-plus</v-icon>-->
        <!--Prescription-->
        <!--</v-btn>-->
      </v-flex>
      <v-flex sm12 lg12 xs12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='patientId'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex sm9>
            <v-btn :loading='loading' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap> </v-layout>
      </v-flex>
      <v-flex sm12 lg12>
        <OldPatientDlg
          :dlg-show='dlgShow'
          @close='closeDlg'
          @add='addNewPatient'
        ></OldPatientDlg>
        <SurveyDlg
          :dlg-survey='dlgSurvey'
          :patient='patient'
          @close='closeDlg'
          @add='addAllergy'
        ></SurveyDlg>
        <ExamDlg
          :dlg-exam='dlgExam'
          :pid='patient.Id'
          @close='closeDlg'
          @add='addExam'
        ></ExamDlg>
        <InvestDlg
          :dlg-invest='dlgInvest'
          :pid='patient.Id'
          @close='closeDlg'
          @add='addInvest'
        ></InvestDlg>
        <DigDlg
          :dlg-dig='dlgDig'
          :pid='patient.Id'
          @close='closeDlg'
          @add='addDigDlg'
        ></DigDlg>
        <OldLabCreateDlg
          :dlg-lab='dlgLab'
          :pid='patient.Id'
          @close='closeDlg'
          @add='addLabReport'
        ></OldLabCreateDlg>
        <SptDlg
          :dlg-spt='dlgSpt'
          :pid='patient.Id'
          @close='closeDlg'
          @add='addSpt'
        ></SptDlg>
        <PreDlg
          :dlg-pre='dlgPre'
          :pid='patient.Id'
          :drugs-pre='drugsPre'
          @close='closeDlg'
          @add='addPrescription'
        ></PreDlg>
      </v-flex>
      <v-flex sm12 lg12>
        <ExpAllergy
          :patient='patient'
          :allergy='allergy'
          :exam='exam'
          :invest='invest'
          :diagnosis='diagnosis'
          :lab-reports='labReports'
          :spt='spt'
          :prescriptions='prescriptions'
        ></ExpAllergy>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DigDlg from '../../components/diagnoses/OldDiagnosisDlg';
import OldPatientDlg from '../../components/patients/OldPatientDlg';
import SurveyDlg from '../../components/forms/AllergySurveyDlg';
import ExamDlg from '../../components/exam/OldExamDlg';
import InvestDlg from '../../components/investigations/OldInvestDlg';
import ExpAllergy from '../../components/forms/ExpAllergy';
import OldLabCreateDlg from '../../components/lab/OldLabCreateDlg';
import SptDlg from '../../components/spt/OldSptDlg';
import PreDlg from '../../components/prescirptions/SmPrescDlg';
import WebH from '../../api/web';
export default {
  components: {
    PreDlg,
    SptDlg,
    InvestDlg,
    ExamDlg,
    OldPatientDlg,
    SurveyDlg,
    ExpAllergy,
    DigDlg,
    OldLabCreateDlg
  },
  data() {
    return {
      loading: false,
      patients: [],
      patientId: '',
      dlgShow: false,
      dlgSurvey: false,
      dlgExam: false,
      dlgInvest: false,
      dlgDig: false,
      dlgLab: false,
      dlgSpt: false,
      dlgPre: false,
      patient: {},
      allergy: {},
      exam: {},
      invest: {},
      diagnosis: {},
      labReports: [],
      spt: [],
      prescriptions: [],
      drugsPre: []
    };
  },
  methods: {
    closeDlg() {
      this.dlgShow = false;
      this.dlgSurvey = false;
      this.dlgExam = false;
      this.dlgInvest = false;
      this.dlgDig = false;
      this.dlgLab = false;
      this.dlgSpt = false;
      this.dlgPre = false;
    },
    createPrescription() {
      if (this.prescriptions.length > 0) {
        this.drugsPre = JSON.parse(
          this.prescriptions[this.prescriptions.length - 1].Drugs
        );
      } else {
        this.drugsPre = [];
      }
      this.dlgPre = true;
    },
    addPrescription(data) {
      this.prescriptions.push(data);
    },
    addSpt(data) {
      this.spt.push(data);
    },
    addLabReport(data) {
      this.labReports.push(data);
    },
    addInvest(data) {
      this.invest = data;
      this.$notify({ type: 'info', text: 'Successfully added investigations' });
    },
    addDigDlg(data) {
      this.diagnosis = data;
      this.$notify({ type: 'info', text: 'Successfully added diagnosis' });
    },
    addNewPatient(patient) {
      this.patient = patient;
      this.allergy = {};
      this.exam = {};
      this.invest = {};
      this.labReports = [];
      this.spt = [];
      this.prescriptions = [];
      this.$notify({ type: 'info', text: 'Successfully added new patient' });
    },
    addAllergy(allergy) {
      this.allergy = allergy;
      this.$notify({ type: 'info', text: 'Successfully added allergy survey' });
    },
    addExam(data) {
      this.exam = data;
      this.$notify({
        type: 'info',
        text: 'Successfully added examination details'
      });
    },

    async getRelatedData() {
      this.Api_GetAllergyDetails();
      this.Api_GetExam();
      this.Api_GetInvest();
      this.Api_GetDiagnosis();
      this.Api_GetLabReports();
      this.Api_GetSpts();
      this.Api_GetPrescriptions();
    },
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.loading = true;
      this.patient = {};
      this.allergy = {};
      this.exam = {};
      this.invest = {};
      this.labReports = [];
      this.spt = [];
      this.prescriptions = [];
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          await this.getRelatedData();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },
    async Api_GetAllergyDetails() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.allergy = {};
      const resp = await WebH.Get(
        '/allergies/patient/' + this.patient.Id,
        'allergy survey'
      );
      if (resp.Success) {
        if (resp.Data.Id) {
          this.allergy = resp.Data;
        }
      }
    },
    async Api_GetExam() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.exam = {};
      const resp = await WebH.Get('/exams/' + this.patient.Id, 'examinations');
      if (resp.Success) {
        if (resp.Data.Id) {
          this.exam = resp.Data;
        }
      }
    },
    async Api_GetInvest() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.invest = {};
      const resp = await WebH.Get(
        '/invest/' + this.patient.Id,
        'investigations'
      );
      if (resp.Success) {
        if (resp.Data.Id) {
          this.invest = resp.Data;
        }
      }
    },
    async Api_GetDiagnosis() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.diagnosis = {};
      const resp = await WebH.Get('/diagnoses/' + this.patient.Id, 'diagnoses');
      if (resp.Success) {
        if (resp.Data.Id) {
          this.diagnosis = resp.Data;
        }
      }
    },
    async Api_GetLabReports() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.labReports = [];
      const resp = await WebH.Get('/ige/' + this.patient.Id, 'lab reports');
      if (resp.Success) {
        if (resp.Data.length > 0) this.labReports = resp.Data;
      }
    },
    async Api_GetSpts() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.spt = [];
      const resp = await WebH.Get('/spt/' + this.patient.Id + '/all', 'SPTs');
      if (resp.Success) {
        if (resp.Data.length > 0) this.spt = resp.Data;
      }
    },
    async Api_GetPrescriptions() {
      if (this.patient === null || this.patient.Id === null) {
        return;
      }
      this.prescriptions = [];
      const resp = await WebH.Get(
        '/Prescriptions/' + this.patient.Id,
        'Prescriptions'
      );
      if (resp.Success) {
        if (resp.Data.length > 0) {
          this.prescriptions = resp.Data;
          this.prescriptions.sort((a, b) => {
            return a.Id - b.Id;
          });
        }
      }
    }
  }
};
</script>
<style scoped></style>
