<template>
  <v-expansion-panel>
    <v-expansion-panel-content>
      <template v-slot:header>
        <div class='subheading font-weight-medium'>
          Patient details
        </div>
      </template>
      <SmPatient :patient='patient'></SmPatient>
    </v-expansion-panel-content>
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Complaints-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmProblems :model='Problems'></SmProblems>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Past treatments-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmTreatments :treatments='Treatments'></SmTreatments>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Family history-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmFamily :family='Family'></SmFamily>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Habits-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmHabits :habits='Habits'></SmHabits>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--More complaints-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmOthers :others='Others'></SmOthers>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--For children-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmChildren :children='Children'></SmChildren>-->
    <!--</v-expansion-panel-content>-->
    <v-expansion-panel-content>
      <template v-slot:header>
        <div class='subheading font-weight-medium'>
          Examination
        </div>
      </template>
      <SmExam :answer='Answer'></SmExam>
    </v-expansion-panel-content>
    <v-expansion-panel-content>
      <template v-slot:header>
        <div class='subheading font-weight-medium'>
          Investigations
        </div>
      </template>
      <SmInvest :invest='Invest'></SmInvest>
    </v-expansion-panel-content>
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Diagnosis-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmDiagnose :diagnosis='diagnosis'></SmDiagnose>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--SPTs-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmSpt :items='spt' loading='false'></SmSpt>-->
    <!--</v-expansion-panel-content>-->
    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Lab Reports-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmLabReport :lab-reports='labReports'></SmLabReport>-->
    <!--</v-expansion-panel-content>-->

    <!--<v-expansion-panel-content>-->
    <!--<template v-slot:header>-->
    <!--<div class='subheading font-weight-medium'>-->
    <!--Prescriptions-->
    <!--</div>-->
    <!--</template>-->
    <!--<SmPrescription :prescriptions='prescriptions'></SmPrescription>-->
    <!--</v-expansion-panel-content>-->
  </v-expansion-panel>
</template>
<script>
import SmPatient from '../patients/SmPatinetDtl';
// import SmProblems from './SmProblems';
// import SmTreatments from './SmTreatments';
// import SmFamily from './SmFamily';
// import SmHabits from './SmHabits';
// import SmOthers from './SmOthers';
// import SmChildren from './SmChildren';
import SmExam from '../exam/SmExams';
import SmInvest from '../investigations/SmInvest';
//import SmDiagnose from '../diagnoses/SmDiagnoses';
// import SmLabReport from '../lab/LabDis';
//import SmSpt from '../spt/SptsDisplay';
// import SmPrescription from '../prescirptions/SmPresc';
export default {
  components: {
    // SmPrescription,
    //SmSpt,
    // SmLabReport,
    SmInvest,
    SmExam,
    //SmDiagnose,
    // SmChildren,
    // SmOthers,
    // SmHabits,
    // SmFamily,
    // SmTreatments,
    // SmProblems,
    SmPatient
  },
  props: {
    allergy: {
      type: Object,
      default: null
    },
    patient: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    invest: {
      type: Object,
      default: null
    },
    diagnosis: {
      type: Object,
      default: null
    },
    labReports: {
      type: Array,
      default: () => {
        return [];
      }
    },
    spt: {
      type: Array,
      default: () => {
        return [];
      }
    },
    prescriptions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    Problems() {
      if (this.allergy.Problems) {
        return JSON.parse(this.allergy.Problems);
      }
      return {
        eyes: { choices: [], others: '', since: '' },
        nose: { choices: [], others: '', since: '' },
        ears: { choices: [], others: '', since: '' },
        mouth: { choices: [], others: '', since: '' },
        chest: { choices: [], others: '', since: '' },
        skin: { choices: [], others: '', since: '' },
        sleep: { choices: [], others: '', since: '' },
        stomach: { choices: [], others: '', since: '' },
        others: { choices: [], others: '', since: '' }
      };
    },
    Treatments() {
      if (this.allergy.Treatments) {
        return JSON.parse(this.allergy.Treatments);
      }
      return {
        drugAllergy: '',
        surgery: '',
        medication: { db: '', bp: '', th: '', others: '' },
        dental: { choices: [], others: '' }
      };
    },
    Family() {
      if (this.allergy.Family) {
        return JSON.parse(this.allergy.Family);
      }
      return {
        mother: { illness: '', surgery: '', allergies: '' },
        father: { illness: '', surgery: '', allergies: '' },
        grandparents: { illness: '', surgery: '', allergies: '' },
        siblings: { illness: '', surgery: '', allergies: '' },
        uncleAunt: { illness: '', surgery: '', allergies: '' }
      };
    },
    Habits() {
      if (this.allergy.Habits) {
        return JSON.parse(this.allergy.Habits);
      }
      return {
        smoking: { choices: [], daily: '', start: '', stopped: '' },
        alcohol: { choices: [], daily: '', start: '', stopped: '' },
        travel: { choices: [], details: '' },
        pets: '',
        diet: '',
        timeSpent: { home: '', work: '', other: '' }
      };
    },
    Others() {
      if (this.allergy.Others) {
        return JSON.parse(this.allergy.Others);
      }
      return {
        house: { choices: [], age: '' },
        smell: { choice: '', since: '' },
        location: { choices: [], details: '' },
        leak: '',
        insects: '',
        time: '',
        place: { choices: [] },
        worse: { choices: [], others: '' },
        month: { choices: [] },
        exposed: { choices: [], others: '', details: '' },
        influence: '',
        past: '',
        hobbies: { choices: '', details: '' },
        food: { choices: '', why: '', stopped: '', describe: '' }
      };
    },
    Children() {
      if (this.allergy.Children) {
        return JSON.parse(this.allergy.Children);
      }
      return {
        delivery: '',
        colic: '',
        diet: '',
        fed: { choice: '', months: '' },
        fedNo: { choice: '', month: '', solidFrom: '' },
        nurse: '',
        infections: { months3: '', yr: '' },
        otherInfo: ''
      };
    },
    Answer() {
      if (this.exam.PId) {
        return this.exam;
      }
      return {
        Height: 0,
        Weight: 0,
        BMI: 0,
        PR: 0,
        Temp: 0,
        BP: ''
      };
    },
    Invest() {
      if (this.invest.PId) {
        return this.invest;
      }
      return {
        CRP: '',
        CRPSt: '',
        Urea: '',
        Skin: '',
        Sweat: '',
        TFT_T3: '',
        TFT_T4: '',
        TFT_TSH: '',
        TFT_Sputum: '',
        PFT_FVC: '',
        PFT_FEV1_Pre: '',
        PFT_FEV1_Post: '',
        PFT_FEV1_FVC: '',
        DLco: '',
        Nasal: '',
        Mucous_R: '',
        Mucous_L: '',
        Nasal_Rt_N: '',
        Nasal_Rt_L: '',
        Nasal_Rt_E: '',
        Nasal_Lt_N: '',
        Nasal_Lt_L: '',
        Nasal_Lt_E: '',
        Conj_Rt_N: '',
        Conj_Rt_L: '',
        Conj_Rt_E: '',
        Conj_Lt_N: '',
        Conj_Lt_L: '',
        Conj_Lt_E: '',
        Serum: '',
        Th_Pressure: '',
        Th_Heat: '',
        Th_Cold: '',
        Th_Sweat: '',
        ATgAB: '',
        ATPO: '',
        ANA: '',
        Influenza: '',
        Pneumococcal: ''
      };
    },
    Diagnosis() {
      if (this.diagnoses.PId) {
        return this.diagnosis;
      }
      return [];
    }
  }
};
</script>

<style scoped></style>
