<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : 'Edit' }} registered patient
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.trim='dlgModel.Name'
          name='fName'
          label='Full Name:'
          v-validate='{ required: true }'
          :error='!!errors.has("fName")'
          :error-messages='errors.first("fName")'
        ></v-text-field>
        <v-text-field
          v-model='dlgModel.PatientId'
          name='patientId'
          label='Patient Id:'
        ></v-text-field>
        <v-text-field
          v-model.trim='dlgModel.Email'
          name='email'
          label='Email:'
        ></v-text-field>
        <v-text-field
          v-model.trim='dlgModel.Mobile'
          name='mobile'
          label='Mobile Number:'
          v-validate='{ required: true, min: 10, max: 20 }'
          :error='!!errors.has("mobile")'
          :error-messages='errors.first("mobile")'
        ></v-text-field>
        <v-textarea
          v-model.trim='dlgModel.Address'
          name='address'
          label='Address:'
          no-resize
          rows='2'
        ></v-textarea>
        <v-text-field
          label='City:'
          v-model='dlgModel.City'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='State:'
          v-model='dlgModel.State'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='Country:'
          v-model='dlgModel.Country'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='PIN:'
          v-model='dlgModel.PIN'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field label='Age:' v-model.number='dlgModel.Age'>
        </v-text-field>
        <v-autocomplete
          :items='genders'
          v-model='dlgModel.Gender'
          label='Gender:'
          name='gender'
          item-text='Text'
          item-value='Id'
          v-validate='{ required: true }'
          :error='!!errors.has("gender")'
          :error-messages='errors.first("gender")'
        >
        </v-autocomplete>
        <v-menu
          ref='dtMenu'
          :close-on-content-click='false'
          lazy
          nudge-right='40'
          offset-y
          full-width
          max-width='290px'
          min-width='290px'
          v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
              v-model='dlgModel.RegisteredDate'
              label='Select a date:'
              v-on='on'
              name='rDt'
              readonly
              v-validate='{ required: true }'
              :error='!!errors.has("rDt")'
              :error-messages='errors.first("rDt")'
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='dlgModel.RegisteredDate'
            no-title
            @input='dtMenu = false'
          ></v-date-picker>
        </v-menu>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';
import Helper from '../../api/helperMethods';
export default {
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    dlgType: {
      type: Number,
      default: 0
    },
    patient: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    dlgShow: function() {
      if (this.patient.Id) {
        this.dlgModel = {
          Name: this.patient.Name,
          PatientId: this.patient.PatientId,
          Age: Helper.CalculateAge(this.patient.DOB),
          Email: this.patient.Email,
          Mobile: this.patient.Mobile,
          Address: this.patient.Address,
          City: this.patient.City,
          State: this.patient.State,
          Country: this.patient.Country,
          PIN: this.patient.PIN,
          Gender: this.patient.Gender,
          RegisteredDate: this.$options.filters.FormatDateISO(
            this.patient.RegisteredDate
          )
        };
      }
    }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      dlgModel: {
        Name: '',
        PatientId: '',
        Age: '',
        Email: '',
        Mobile: '',
        Address: '',
        City: '',
        State: 'Karnataka',
        Country: 'India',
        PIN: '',
        Gender: '',
        RegisteredDate: ''
      },
      genders: [
        { Id: 0, Text: 'Male' },
        { Id: 1, Text: 'Female' },
        { Id: 2, Text: 'Others' }
      ]
    };
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        Name: '',
        PatientId: '',
        Age: '',
        Email: '',
        Mobile: '',
        Address: '',
        City: '',
        State: '',
        Country: '',
        PIN: '',
        Gender: '',
        RegisteredDate: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      if (this.dlgType === 0) this.api_create(this.dlgModel);
      else {
        this.dlgModel.Id = this.patient.Id;
        this.api_update(this.dlgModel);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/patients/old', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      const res = await WebH.Put('/patients/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
