<template>
  <v-dialog v-model='dlgSurvey' fullscreen>
    <v-card>
      <v-toolbar dark color='primary'>
        <v-btn icon dark @click='close'>
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Fill allergy survey form</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark flat @click='saveData'>Save</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <AllergySurvey ref='allergyComp'></AllergySurvey>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AllergySurvey from './PatientHistory';
import WebH from '../../api/web';
export default {
  components: {
    AllergySurvey
  },
  props: {
    patient: { type: Object, default: null },
    dlgSurvey: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async notify(message) {
      await this.$notifyDlg.show(message, 'Error', 'error');
    },
    async checkForPatientDetails() {
      return this.patient.PatientId !== '' && this.patient.Mobile !== '';
    },
    async saveData() {
      const patientCheck = await this.checkForPatientDetails();
      if (!patientCheck) {
        await this.notify('Fill correct patient details');
        return;
      }
      const vModel = this.$refs.allergyComp.setDataToStore();
      vModel.PatientId = this.patient.PatientId;
      vModel.Mobile = this.patient.Mobile;
      this.submit(vModel);
    },
    async submit(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/allergies', data);
      loader.hide();
      if (res.Success) {
        this.$emit('add', data);
        this.$refs.allergyComp.clear();
        this.close();
      } else {
        await this.notify(res.Data);
      }
    }
  }
};
</script>

<style scoped></style>
