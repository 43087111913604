<template>
  <v-dialog v-model='dlgLab' scrollable persistent max-width='800px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          Add lab report
        </div>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-xs fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <div class='body-2'>Test type:</div>
              <v-radio-group v-model='testType' row>
                <v-radio
                  label='Airborne Allergens'
                  :value='1'
                  color='primary'
                />
                <v-radio label='Food Allergens' :value='2' color='primary' />
                <v-radio label='Non-Veg Allergens' :value='3' color='primary' />
              </v-radio-group>
            </v-flex>
            <v-flex xs6 style='padding-right: 5px'>
              <v-menu
                ref='dtMenu'
                :close-on-content-click='false'
                lazy
                nudge-right='40'
                offset-y
                full-width
                max-width='290px'
                min-width='290px'
                v-model='dtMenu'
              >
                <template v-slot:activator='{ on }'>
                  <v-text-field
                    v-model='dlgModel.SampleDate'
                    label='Report date:'
                    v-on='on'
                    name='rDt'
                    readonly
                    v-validate='{ required: true }'
                    :error='!!errors.has("rDt")'
                    :error-messages='errors.first("rDt")'
                  >
                    <template slot='append'>
                      <v-icon class='filterIcon grey--text text--darken-1'
                        >fa fa-calendar</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model='dlgModel.SampleDate'
                  no-title
                  @input='dtMenu = false'
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6 style='padding-left: 5px'>
              <v-text-field
                label='Lab In-charge:'
                v-model='dlgModel.LabIn'
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                label='Remarks:'
                v-model='dlgModel.Remarks'
              ></v-text-field>
            </v-flex>

            <v-flex xs6 style='padding-right: 5px'>
              <v-autocomplete
                label='Allergen:'
                v-model='allergen.Name'
                :items='allergens'
                clearable
              ></v-autocomplete>
            </v-flex>
            <v-flex xs3 style='padding-left: 5px;padding-right: 5px;'>
              <v-text-field
                label='*SAU/ml:'
                v-model.number='allergen.Reading'
              ></v-text-field>
            </v-flex>
            <v-flex xs3 style='padding-left: 5px;'>
              <v-btn @click='addItem'>Add Allergen</v-btn>
            </v-flex>
            <v-flex xs12>
              <br />
              <v-data-table
                :headers='headers'
                :items='dlgModel.RValues'
                hide-actions
                class='elevation-2'
                style='height: 200px;overflow: auto'
              >
                <template slot='items' slot-scope='props'>
                  <tr>
                    <td>{{ props.item.Name }}</td>
                    <td>{{ props.item.Reading }}</td>
                    <td style='text-align: center'>
                      <button
                        class='linkBtn'
                        type='button'
                        @click='removeItem(props.item)'
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>

            <div v-show='submit.error'>
              <v-list two-line>
                <v-list-item>
                  <span class='red--text'>{{ submit.message }}</span>
                </v-list-item>
              </v-list>
            </div>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { airAllergyMixin } from '../../api/airAlergyMixin';
import { vegAllergyMixin } from '../../api/vegAllergyMixin';
import { nVegAllergyMixin } from '../../api/nVegAllergyMixin';
import WebH from '../../api/web';
export default {
  mixins: [airAllergyMixin, vegAllergyMixin, nVegAllergyMixin],
  props: {
    pid: { type: Number, default: 0 },
    dlgLab: { type: Boolean, default: false }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      testType: 1,
      dlgModel: {
        SampleDate: '',
        RTitle: '',
        RValues: [],
        TestValues: [],
        Remarks: '',
        LabIn: ''
      },
      headers: [
        {
          text: 'Allergen',
          value: 'Name',
          sortable: true,
          width: '10%'
        },
        {
          text: '*SAU/ml',
          value: 'Reading',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          width: '5%',
          align: 'center'
        }
      ],
      dtMenu: false,
      allergen: {
        Name: '',
        Reading: 0
      }
    };
  },
  computed: {
    allergens() {
      const arr = [];
      for (let i = 0; i < this.airAllergens.length; i++) {
        const rows = this.airAllergens[i];
        for (let j = 0; j < rows.length; j++) {
          if (i === 0 && (j === 0 || j === 1)) continue;
          arr.push(rows[j]);
        }
      }
      for (let i = 0; i < this.vegAllergens.length; i++) {
        const rows = this.vegAllergens[i];
        for (let j = 0; j < rows.length; j++) {
          if (i === 0 && (j === 0 || j === 1)) continue;
          arr.push(rows[j]);
        }
      }
      for (let i = 0; i < this.nVegAllergens.length; i++) {
        const rows = this.nVegAllergens[i];
        for (let j = 0; j < rows.length; j++) {
          if (i === 0 && (j === 0 || j === 1)) continue;
          arr.push(rows[j]);
        }
      }
      return arr;
    }
  },
  methods: {
    closeDlg() {
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.allergen = {
        Name: '',
        Reading: 0
      };
      this.dlgModel = {
        RTitle: '',
        RValues: [],
        TestValues: [],
        Remarks: '',
        SampleDate: '',
        LabIn: ''
      };
      this.$emit('close');
    },
    async addItem() {
      if (!this.allergen.Name) {
        await this.ShowMsg('Allergen name required');
        return;
      }
      if (!this.allergen.Reading) {
        await this.ShowMsg('*SAU/ml is required');
        return;
      }
      if (!Number(this.allergen.Reading)) {
        await this.ShowMsg('Invalid *SAU/ml');
        return;
      }
      this.dlgModel.RValues.push(Object.assign({}, this.allergen));
      this.allergen = { Name: '', Reading: 0 };
    },
    removeItem(item) {
      const index = this.dlgModel.RValues.indexOf(item);
      if (index > -1) {
        this.dlgModel.RValues.splice(index, 1);
      }
    },
    async ShowMsg(msg) {
      await this.$notifyDlg.show(msg, 'Error', 'error');
    },
    async saveDlg() {
      if (this.pid < 1) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      if (this.dlgModel.RValues.length < 1) {
        await this.$notifyDlg.show(
          'Add allergen before saving',
          'Error',
          'error'
        );
        return;
      }
      this.dlgModel.PId = this.pid;
      if (this.testType === 1)
        this.dlgModel.RTitle =
          'Specific IgE Test for Airborne Allergens by EIA';
      else if (this.testType === 2)
        this.dlgModel.RTitle = 'Specific IgE Test for Food Allergens by EIA';
      else
        this.dlgModel.RTitle = 'Specific IgE Test for Non-Veg Allergens by EIA';
      this.api_create(this.dlgModel);
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/ige/old', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
