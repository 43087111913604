<template>
  <v-card class='elevation-0'>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color='primary' outline @click='showDlg'>Edit</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex xs12>
          <div class='body-1'>PEFR:</div>
          <v-layout row wrap>
            <v-flex xs3>Pred: {{ invest.PEFRPred }} </v-flex>
            <v-flex xs3>Pre: {{ invest.PEFRPre }} </v-flex>
            <v-flex xs3>Post: {{ invest.PEFRPost }}</v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs3>SpO2: {{ invest.SpO2 }} %</v-flex>
        <v-flex xs3>Nasal flow rate: {{ invest.Nasal }}</v-flex>
        <v-flex xs6> DLco:{{ invest.DLco }}</v-flex>
        <v-flex xs3>CRP: {{ invest.CRP }} {{ invest.CRPSt }}</v-flex>
        <v-flex xs3> Urea breath test: {{ invest.Urea }} </v-flex>
        <v-flex xs3> Skin-KOH:{{ invest.Skin }} </v-flex>
        <v-flex xs3> Sweat chloride test:{{ invest.Sweat }} </v-flex>
        <v-flex xs12>
          <v-divider></v-divider>
          <div class='body-1'>TFT:</div>
          <v-layout row wrap>
            <v-flex xs3> T3:{{ invest.TFT_T3 }} </v-flex>
            <v-flex xs3> T4:{{ invest.TFT_T4 }} </v-flex>
            <v-flex xs3> TSH: {{ invest.TFT_TSH }}</v-flex>
            <v-flex xs3> Sputum:{{ invest.TFT_Sputum }}</v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs12>
          <div class='body-1'>PFT:</div>
          <v-layout row wrap>
            <v-flex xs3> FVC:{{ invest.PFT_FVC }} </v-flex>
            <v-flex xs3> FEV1 Pre:{{ invest.PFT_FEV1_Pre }} </v-flex>
            <v-flex xs3> FEV1 Post:{{ invest.PFT_FEV1_Post }} </v-flex>
            <v-flex xs3> FEV1/FVC:{{ invest.PFT_FEV1_FVC }} </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs12>
          <div class='body-1'>Mucous clearance test:</div>
          <v-layout row wrap>
            <v-flex xs3>R: {{ invest.Mucous_R }} </v-flex>
            <v-flex xs3>L: {{ invest.Mucous_L }} </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs12>
          <div class='body-1'>Nasal smear test:</div>
          <div>Rt:</div>
          <v-layout row wrap>
            <v-flex xs3>N:{{ invest.Nasal_Rt_N }} </v-flex>
            <v-flex xs3>L:{{ invest.Nasal_Rt_L }} </v-flex>
            <v-flex xs3>E:{{ invest.Nasal_Rt_E }} </v-flex>
          </v-layout>
          <div>Lt:</div>
          <v-layout row wrap>
            <v-flex xs3>N:{{ invest.Nasal_Lt_N }} </v-flex>
            <v-flex xs3>L:{{ invest.Nasal_Lt_L }} </v-flex>
            <v-flex xs3>E:{{ invest.Nasal_Lt_E }} </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs12>
          <div class='body-1'>Conjunctiva smear test:</div>
          <div>Rt:</div>
          <v-layout row wrap>
            <v-flex xs3>N: {{ invest.Conj_Rt_N }} </v-flex>
            <v-flex xs3>L: {{ invest.Conj_Rt_L }} </v-flex>
            <v-flex xs3>E: {{ invest.Conj_Rt_E }}</v-flex>
          </v-layout>
          <div>Lt:</div>
          <v-layout row wrap>
            <v-flex xs3>N: {{ invest.Conj_Lt_N }}</v-flex>
            <v-flex xs3>L: {{ invest.Conj_Lt_L }} </v-flex>
            <v-flex xs3>E: {{ invest.Conj_Lt_E }} </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs12>
          <div class='body-1'>Thermo stimulatory test:</div>
          <v-layout row wrap>
            <v-flex xs3>Pressure:{{ invest.Th_Pressure }}</v-flex>
            <v-flex xs3>Heat: {{ invest.Th_Heat }}</v-flex>
            <v-flex xs3>Cold: {{ invest.Th_Cold }}</v-flex>
            <v-flex xs3>Sweat: {{ invest.Th_Sweat }}</v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs3>Serum test: {{ invest.Serum }}</v-flex>
        <v-flex xs3>ATgAB: {{ invest.ATgAB }}</v-flex>
        <v-flex xs3>ATPO: {{ invest.ATPO }}</v-flex>
        <v-flex xs3>ANA: {{ invest.ANA }}</v-flex>
      </v-layout>

      <div>
        <InvestDlg
          :dlg-type='1'
          :dlg-invest='dlgShow'
          @close='closeDlg'
          @add='addDlg'
          :obj='invest'
        ></InvestDlg>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import InvestDlg from './OldInvestDlg';
export default {
  components: {
    InvestDlg
  },
  props: ['invest'],
  data() {
    return {
      dlgShow: false
    };
  },
  methods: {
    showDlg() {
      if (this.invest.Id) {
        this.dlgShow = true;
      }
    },
    closeDlg() {
      this.dlgShow = false;
    },
    async addDlg() {
      await this.$notifyDlg.show(
        'To get the updated details please reload patient data by searching again',
        'Info',
        'info'
      );
    }
  }
};
</script>

<style scoped></style>
