<template>
  <v-dialog v-model='dlgExam' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : 'Edit' }} clinical details
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.number='dlgModel.Height'
          label='Height (cms):'
        ></v-text-field>
        <v-text-field
          v-model.number='dlgModel.Weight'
          label='Weight (Kg):'
        ></v-text-field>
        <v-text-field
          v-model.number='dlgModel.PR'
          label='Pulse rate (bpm):'
        ></v-text-field>
        <v-text-field
          v-model.number='dlgModel.Temp'
          label='Temperature (Celsius):'
        ></v-text-field>
        <v-text-field
          v-model='dlgModel.BP'
          label='BP (high / low mmHg):'
        ></v-text-field>
        <v-menu
          ref='dtMenu'
          :close-on-content-click='false'
          lazy
          nudge-right='40'
          offset-y
          full-width
          max-width='290px'
          min-width='290px'
          v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
              v-model='dlgModel.AnsDate'
              label='Select a date:'
              v-on='on'
              name='rDt'
              readonly
              v-validate='{ required: true }'
              :error='!!errors.has("rDt")'
              :error-messages='errors.first("rDt")'
              :disabled='dlgType > 0'
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='dlgModel.AnsDate'
            no-title
            @input='dtMenu = false'
          ></v-date-picker>
        </v-menu>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';

export default {
  props: {
    pid: { type: Number, default: 0 },
    dlgExam: { type: Boolean, default: false },
    dlgType: {
      type: Number,
      default: 0
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    dlgExam: function() {
      if (this.obj.Id) {
        this.dlgModel = {
          Height: this.obj.Height,
          Weight: this.obj.Weight,
          PR: this.obj.PR,
          Temp: this.obj.Temp,
          BP: this.obj.BP,
          AnsDate: this.$options.filters.FormatDateISO(this.obj.AnsweredDate)
        };
      }
    }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      dlgModel: {
        Height: 0,
        Weight: 0,
        PR: 0,
        Temp: 0,
        BP: '',
        AnsDate: ''
      }
    };
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        Height: 0,
        Weight: 0,
        PR: 0,
        Temp: 0,
        BP: '',
        AnsDate: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      if (this.pid < 1 && this.dlgType === 0) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      if (this.dlgType === 0) {
        this.dlgModel.PId = this.pid;
        this.api_create(this.dlgModel);
      } else {
        this.dlgModel.Id = this.obj.Id;
        this.api_update(this.dlgModel);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/exams/old', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      const res = await WebH.Put('/exams/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
