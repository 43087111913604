<template>
  <v-card class='elevation-0'>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color='primary' outline @click='showDlg'>Edit</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex sm3> Height:&nbsp;&nbsp; {{ answer.Height }} cm </v-flex>
        <v-flex sm3> Weight:&nbsp;&nbsp; {{ answer.Weight }} Kg</v-flex>
        <v-flex sm3>
          BMI:&nbsp;&nbsp; {{ answer.BMI.toFixed(1) }} Kg/m<sup>2</sup></v-flex
        >
        <!--<v-flex sm3> PEFR Pred:&nbsp;&nbsp; {{ answer.PEFRPred }} </v-flex>-->
        <!--<v-flex sm3>-->
        <!--PEFR Pre Nebulisation:&nbsp;&nbsp; {{ answer.PEFRPre }}-->
        <!--</v-flex>-->
        <!--<v-flex sm3>-->
        <!--PEFR Post Nebulisation:&nbsp;&nbsp; {{ answer.PEFRPost }}-->
        <!--</v-flex>-->
        <v-flex sm3>
          Pulse rate:&nbsp;&nbsp; {{ answer.PR }} beats/minute</v-flex
        >
        <v-flex sm3> Temperature:&nbsp;&nbsp; {{ answer.Temp }} &deg;C</v-flex>
        <v-flex sm3> Blood pressure:&nbsp;&nbsp; {{ answer.BP }} mmHg</v-flex>
        <!--<v-flex sm3> SpO<sub>2</sub>:&nbsp;&nbsp; {{ answer.SpO2 }} %</v-flex>-->
      </v-layout>
      <div>
        <ExamDlg
          :dlg-exam='dlgShow'
          :dlg-type='1'
          @close='closeDlg'
          @add='addDlg'
          :obj='answer'
        ></ExamDlg>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ExamDlg from './OldExamDlg';
export default {
  components: {
    ExamDlg
  },
  props: ['answer'],
  data() {
    return {
      dlgShow: false
    };
  },
  methods: {
    showDlg() {
      if (this.answer.Id) {
        this.dlgShow = true;
      }
    },
    closeDlg() {
      this.dlgShow = false;
    },
    async addDlg() {
      await this.$notifyDlg.show(
        'To get the updated details please reload patient data by searching again',
        'Info',
        'info'
      );
    }
  }
};
</script>

<style scoped></style>
